import React from 'react';
import classNames from 'classnames';

import Overlay from '../Overlay/Overlay';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';

import styles from './Modal.module.scss';

const Modal = ({
  className,
  children,
  isOpen,
  onOverlayClick,
  title,
  customTitle,
  closeModal,
  hiddenCloseIcon = false,
}) => {
  return (
    <Overlay isOpen={isOpen} onClick={onOverlayClick}>
      <div className={classNames(styles.modal, className)}>
        <header className={styles.header}>
          {!customTitle && <h3 className={styles.title}>{title}</h3>}
          {!!customTitle && customTitle}
          {!hiddenCloseIcon && (
            <span className={styles.closeIcon} onClick={closeModal}>
              <CloseIcon />
            </span>
          )}
        </header>
        <main className={styles.content}>{children}</main>
      </div>
    </Overlay>
  );
};

Modal.displayName = 'modal';
export default Modal;
