import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Checkbox.module.scss';
import { VALIDATION_STATE_INVALID, VALIDATION_STATE_VALID } from '../../utils/useFormValidation';

const Checkbox = ({ children, classList = {}, validationState, disabled = false, ...props }) => {
  const rootStyle = classNames(
    styles.checkboxContainer,
    {
      [styles.disabled]: disabled,
      [styles.valid]: validationState === VALIDATION_STATE_VALID ? true : false,
      [styles.invalid]: validationState === VALIDATION_STATE_INVALID ? true : false,
    },
    classList.root
  );
  return (
    <label className={rootStyle} data-testid={props['data-testid']}>
      <input disabled={disabled} type="checkbox" {...props} data-testid={undefined} />
      <span className={classNames(styles.checkboxButton, classList.checkboxButton)}></span>
      {children}
    </label>
  );
};

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  children: PropTypes.node,
  classList: PropTypes.objectOf(PropTypes.string),
  validationState: PropTypes.oneOf([VALIDATION_STATE_VALID, VALIDATION_STATE_INVALID]),
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  testid: PropTypes.string,
};

export default Checkbox;
