import React, { useState } from 'react';

import { ReactComponent as Checkmark } from '../images/icons/checkmark.svg';

const useFormValidation = () => {
  const [state, setState] = useState({ errors: [], valid: [] });

  function getValidationState(field) {
    const { errors, valid } = state;

    if (errors.includes(field)) {
      return VALIDATION_STATE_INVALID;
    }

    if (valid.includes(field)) {
      return VALIDATION_STATE_VALID;
    }

    return undefined;
  }

  function getValidationIcon(field, classList) {
    const { errors, valid } = state;

    if (errors.includes(field)) {
      return <div className={classList}>&times;</div>;
    }

    if (valid.includes(field)) {
      return <Checkmark />;
    }

    return undefined;
  }

  function markFieldAsInvalid(field) {
    setState(({ errors, valid }) => ({
      errors: errors.filter((item) => item !== field).concat([field]),
      valid: valid.filter((item) => item !== field),
    }));
  }

  function markFieldAsValid(field) {
    setState(({ errors, valid }) => ({
      errors: errors.filter((item) => item !== field),
      valid: valid.filter((item) => item !== field).concat([field]),
    }));
  }

  function removeValidationState(e) {
    const { errors, valid } = state;
    const field = e.target.name;
    setState({
      errors: errors.filter((item) => item !== field),
      valid: valid.filter((item) => item !== field),
    });
  }

  function setValidationState(e) {
    const { errors, valid } = state;
    const domElm = e.target;
    const field = e.target.name;

    if (domElm.checkValidity()) {
      setState({
        errors: errors.filter((item) => item !== field),
        valid: valid.filter((item) => item !== field).concat([field]),
      });
      return;
    }

    setState({
      errors: errors.filter((item) => item !== field).concat([field]),
      valid: valid.filter((item) => item !== field),
    });
  }

  function handleSubmit(e) {
    e.preventDefault();

    e.target.checkValidity();

    const fieldsWithErrors = Array.from(e.target.querySelectorAll(':invalid'));
    const fieldsValid = Array.from(e.target.querySelectorAll(':valid'));

    setState({
      errors: fieldsWithErrors.map((el) => el.name),
      valid: fieldsValid.map((el) => el.name),
    });

    return fieldsWithErrors;
  }

  function removeAllValidationStates() {
    setState({
      errors: [],
      valid: [],
    });
  }

  return {
    getValidationIcon,
    getValidationState,
    handleSubmit,
    markFieldAsInvalid,
    markFieldAsValid,
    removeAllValidationStates,
    removeValidationState,
    setState,
    setValidationState,
    formData: state,
  };
};

export default useFormValidation;

export const VALIDATION_STATE_VALID = 'valid';
export const VALIDATION_STATE_INVALID = 'invalid';
