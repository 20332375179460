import React from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import styles from './Overlay.module.scss';

const Overlay = ({ children, isOpen, onClick, classList = {} }) => {
  if (!isOpen || typeof document === 'undefined') {
    return null;
  }
  const rootElm = document.querySelector('body');

  return createPortal(
    <>
      <div className={styles.overlay} onClick={(event) => onClick(event)}></div>
      <div className={classNames(styles.content, classList.root)}>{children}</div>
    </>,
    rootElm
  );
};

Overlay.displayName = 'Overlay';
export default Overlay;
